import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Modal, SubTitle } from 'components';
import { DashboardLimitTimeRanges } from 'enums';
import { useContactGieniUrl } from 'hooks';

import styles from './styles.module.scss';

export const DashboardLimitModal = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [timeRange, setTimeRange] = useState<DashboardLimitTimeRanges>(
    DashboardLimitTimeRanges.Daily
  );
  const contactPageUrl = useContactGieniUrl();
  const handleClose = () => setIsOpen(false);

  const handleButtonClick = () => {
    window.open(contactPageUrl, '_blank');
  };

  useEffect(() => {
    // @ts-expect-error error type
    window.openDashboardLimitModal = (
      newTimeRange: DashboardLimitTimeRanges
    ) => {
      setTimeRange(newTimeRange);
      setIsOpen(true);
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className={styles.container}
      title={t(`Common.CreateDashboardLimit.${timeRange}.Title`)}
      titleClassName={styles.title}
    >
      <SubTitle className={styles.description}>
        {t(`Common.CreateDashboardLimit.${timeRange}.Description`)}
      </SubTitle>

      <Button
        size="medium"
        color="primary"
        variant="contained"
        onClick={handleButtonClick}
        className={styles.button}
      >
        {t(`Common.CreateDashboardLimit.Button`)}
      </Button>
    </Modal>
  );
};
