import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { EmptyState } from 'components';
import { Chat } from 'entities/Chat.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { ChartUnitTypes, InsightsType, queryKeys } from 'enums';
import { TextWidgetStatuses } from 'enums/TextWidgetStatuses.enum';
import { useIsExternalUserDashboard } from 'hooks';
import { queryClient } from 'index';
import { handleAnimationSwitch } from 'utils/helpers/animationHelper';

import { getClassname, getDashboardWidget } from './dashboardHelpers';
import { FloatingFilter } from './FloatingFilter';

import styles from './styles.module.scss';

export type DashboardComponentExtended = DashboardComponent & {
  className: string;
  iconClassName?: string;
};

interface Props {
  isPending?: boolean;
}

export const DashboardV2: FC<Props> = ({ isPending = false }) => {
  const { t } = useTranslation();
  const { dashboardId, messageId } = useParams();
  const [isDashboardPaid, setIsDashboardPaid] = useState(false);

  const isExternalUserDashboard = useIsExternalUserDashboard();
  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  const mappedWidgets = dashboardData?.widgets || [];

  const isTextWidgetsProcessing = useMemo(() => {
    const textSummaryWidget = dashboardData?.widgets?.find(
      ({ type }) => type === InsightsType.TextSummary
    );

    return textSummaryWidget?.params?.status === TextWidgetStatuses.Processing;
  }, [dashboardData?.widgets]);

  useEffect(() => {
    window.addEventListener('beforeprint', () => {
      if (window?.getSelection) {
        window?.getSelection()?.removeAllRanges();
      }
    });
  }, []);

  useEffect(() => {
    setIsDashboardPaid(!!dashboardData?.isPaid);
  }, [dashboardData, dashboardData?.isPaid]);

  useEffect(() => {
    if (dashboardData) handleAnimationSwitch(true);
  }, [dashboardData]);

  useEffect(() => {
    if (dashboardData?.chatId && dashboardData?.originDashboardId) {
      const previousArchivedData: Chat[] =
        queryClient.getQueryData(
          queryKeys.filteredChats({ includeArchived: true })
        ) || [];

      queryClient.setQueryData(
        queryKeys.filteredChats({ includeArchived: true }),
        [
          ...previousArchivedData.map((chatData) => {
            if (dashboardData?.chatId !== chatData.id) {
              return chatData;
            }

            const newDashboards = chatData.dashboards.map((dashboard) => {
              if (dashboard?.id !== dashboardData.id) {
                return dashboard;
              }

              return {
                ...dashboard,
                isPaid: dashboardData.isPaid
              };
            });

            return {
              ...chatData,
              dashboards: newDashboards
            };
          })
        ]
      );
    }
  }, [
    dashboardData?.chatId,
    dashboardData?.id,
    dashboardData?.isPaid,
    dashboardData?.originDashboardId
  ]);

  const getWidgetData = (
    widgetType: InsightsType,
    widgetUnit?: ChartUnitTypes
  ) => {
    const foundWidget = mappedWidgets.find(({ type, params }) => {
      if (!widgetUnit) {
        return type === widgetType;
      }

      return type === widgetType && params?.type === widgetUnit;
    });

    if (foundWidget?.type === InsightsType.MapDots && foundWidget?.params) {
      const manufacturersData = mappedWidgets.find(
        ({ type }) => type === InsightsType.ManufacturerProfiles
      );

      if (manufacturersData) {
        foundWidget.params.companies =
          manufacturersData.params?.companies || null;
      }
    }

    return foundWidget;
  };

  const getWidgetSummary = (
    widgetType: InsightsType,
    widgetUnit?: ChartUnitTypes
  ) => {
    if (
      ![
        InsightsType.PieChart,
        InsightsType.Treemap,
        InsightsType.HierarchyProducts
      ].includes(widgetType)
    ) {
      return '';
    }

    if (widgetType === InsightsType.Treemap) {
      const treemapSummaryWidget = mappedWidgets.find(
        ({ type }) => type === InsightsType.TextTrees
      );

      return treemapSummaryWidget?.params?.text;
    }

    if (widgetType === InsightsType.HierarchyProducts) {
      const hierarchyProductsSummaryWidget = mappedWidgets.find(
        ({ type }) => type === InsightsType.TextProducts
      );

      return hierarchyProductsSummaryWidget?.params?.text;
    }

    const piesSummaryWidget = mappedWidgets.find(
      ({ type }) => type === InsightsType.TextPies
    );

    if (widgetUnit === ChartUnitTypes.TechnologiesPie) {
      return piesSummaryWidget?.params?.technology;
    }

    if (widgetUnit === ChartUnitTypes.MaterialsPie) {
      return piesSummaryWidget?.params?.materials;
    }

    return '';
  };

  const getCard = ({
    title,
    note,
    widgetType,
    widgetUnit,
    currentWidget
  }: {
    note?: string;
    title?: string;
    widgetType: InsightsType;
    widgetUnit?: ChartUnitTypes;
    currentWidget?: DashboardComponent;
  }) => {
    const data = currentWidget || getWidgetData(widgetType, widgetUnit);

    if (!data) {
      return null;
    }

    return getDashboardWidget({
      note,
      title,
      isPaid: isDashboardPaid,
      isTextWidgetsProcessing,
      revision: dashboardData?.revision,
      summary: getWidgetSummary(widgetType, widgetUnit) || '',
      widget: {
        ...(data || {}),
        className: getClassname(widgetType, widgetUnit) || ''
      }
    });
  };

  const hasTreemap = mappedWidgets.some(
    (widget) => widget.type === InsightsType.Treemap
  );

  return (
    <>
      <div className={cn(styles['dashboard-wrapper'])}>
        {!!mappedWidgets?.length && (
          <div
            className={cn(
              styles.dashboard,
              isExternalUserDashboard && styles['small-header']
            )}
          >
            <div
              className={cn(
                styles['main-container'],
                hasTreemap && styles['has-treemap']
              )}
            >
              {getCard({ widgetType: InsightsType.DashboardHistory })}
              {getCard({
                title: t('Page.Dashboard.WidgetLabels.BigNumberCompanies'),
                widgetType: InsightsType.BigNumber,
                widgetUnit: ChartUnitTypes.CompaniesCount
              })}
              {getCard({
                title: t('Page.Dashboard.WidgetLabels.BigNumberEmployees'),
                widgetType: InsightsType.BigNumber,
                widgetUnit: ChartUnitTypes.EmployeeCount
              })}
              {getCard({ widgetType: InsightsType.HierarchyIndustries })}
              {getCard({ widgetType: InsightsType.TextSummary })}
              {getCard({
                widgetType: InsightsType.ReportAd,
                currentWidget: {
                  type: InsightsType.ReportAd,
                  id: InsightsType.ReportAd
                }
              })}
              {getCard({
                widgetType: InsightsType.MapDots,
                title: dashboardData?.title
              })}
              {getCard({
                widgetType: InsightsType.Treemap,
                title: t('Page.Dashboard.WidgetLabels.TreemapV2')
              })}

              {getCard({
                widgetType: InsightsType.PieChart,
                widgetUnit: ChartUnitTypes.TechnologiesPie,
                title: t('Page.Dashboard.WidgetLabels.PieChartTechnologies'),
                note: t('Page.Dashboard.PieChartTechnologies.Note')
              })}
              {getCard({
                widgetType: InsightsType.PieChart,
                widgetUnit: ChartUnitTypes.MaterialsPie,
                title: t('Page.Dashboard.WidgetLabels.PieChartMaterials'),
                note: t('Page.Dashboard.PieChartMaterials.Note')
              })}
              {getCard({
                widgetType: InsightsType.TopModels,
                title: t('Page.Dashboard.TopModels.Title')
              })}
              {getCard({
                widgetType: InsightsType.HierarchyProducts,
                title: t('Page.Dashboard.WidgetLabels.ProductsTreemap')
              })}
              {getCard({
                widgetType: InsightsType.CRMBoosterAd,
                currentWidget: {
                  type: InsightsType.CRMBoosterAd,
                  id: InsightsType.CRMBoosterAd
                }
              })}
              {getCard({
                widgetType: InsightsType.ManufacturerProfiles,
                title: t('Page.Dashboard.WidgetLabels.ManufacturerProfiles')
              })}
              {getCard({
                widgetType: InsightsType.RequestDemoCall,
                currentWidget: {
                  type: InsightsType.RequestDemoCall,
                  id: InsightsType.RequestDemoCall
                }
              })}
            </div>
            {!!dashboardData?.filters?.length && (
              <FloatingFilter data={dashboardData.filters} />
            )}
            {!mappedWidgets?.length && !isPending && (
              <EmptyState className={styles['empty-state']} />
            )}
          </div>
        )}
      </div>
    </>
  );
};
