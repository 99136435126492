import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import loadImage from 'blueimp-load-image';
import { Avatar } from 'components';
import {
  useDeleteUserAvatar,
  useUpdateUserAvatar,
  useUserAvatar
} from 'hooks/api';
import { useUserInfo } from 'hooks/api/useUserInfo';

import styles from './styles.module.scss';

const MAX_FILE_SIZE = 5000000;

interface Props {
  isGoogleUser?: boolean;
}

export const UserAvatar: FC<Props> = () => {
  const { t } = useTranslation();
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const [avatarSrc, setAvatarSrc] = useState('');

  const { data: userInfo } = useUserInfo();
  const { data: userAvatar } = useUserAvatar();
  const { mutate: updateAvatar, isPending: isUpdateAvatarPending } =
    useUpdateUserAvatar();
  const { mutate: deleteAvatar, isPending: isDeleteAvatarPending } =
    useDeleteUserAvatar();

  useEffect(() => {
    if (userAvatar) {
      const imageUrl = URL.createObjectURL(userAvatar);
      setAvatarSrc(imageUrl);
    } else {
      setAvatarSrc('');
    }

    return () => {
      if (avatarSrc) {
        URL.revokeObjectURL(avatarSrc);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAvatar]);

  const handleUpload = (file: File | null) => {
    if (!file) {
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      toast.error(t('Page.Profile.Settings.MaxAvatarFileSizeError'), {
        icon: false
      });
      return;
    }

    if (file) {
      loadImage(
        file,
        (img) => {
          (img as HTMLCanvasElement).toBlob((blob) => {
            if (blob) {
              const formData = new FormData();
              const formDataFile = new File([blob], file.name, {
                type: file.type
              });

              formData.append('file', formDataFile);

              const fileUrl = (img as HTMLCanvasElement).toDataURL();

              updateAvatar({
                file: formData,
                onSuccess: () => {
                  setAvatarSrc(fileUrl);

                  if (uploadInputRef.current) {
                    uploadInputRef.current.value = '';
                  }
                }
              });
            }
          }, file.type);
        },
        {
          orientation: true,
          canvas: true
        }
      );
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      const fileType = file?.type;
      const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      if (!validFileTypes.includes(fileType)) {
        toast.error(t('Page.Profile.Settings.NotValidAvatarFileType'), {
          icon: false
        });
      } else {
        handleUpload(file);
      }
    }
  };

  const onDelete = () => {
    deleteAvatar();
  };

  return (
    <>
      <h4 className={styles.heading}>
        {t('Page.Profile.Settings.ProfilePhoto')}
      </h4>
      <div className={styles.container}>
        <Avatar
          imageUrl={avatarSrc}
          className={styles.avatar}
          name={userInfo?.firstName || userInfo?.email || ''}
        />

        <div className={styles.buttons}>
          <LoadingButton
            size="small"
            color="primary"
            variant="outlined"
            component="label"
            tabIndex={-1}
            role={undefined}
            loading={isUpdateAvatarPending}
            classes={{
              loadingIndicator: styles.indicator
            }}
          >
            {t('Page.Profile.Settings.UploadPhoto')}
            <input
              ref={uploadInputRef}
              type="file"
              onChange={onChange}
              className={styles.input}
              accept="image/jpeg,image/png,image/jpg"
            />
          </LoadingButton>

          <LoadingButton
            size="small"
            color="primary"
            variant="contained"
            onClick={onDelete}
            disabled={!userAvatar}
            className={styles.button}
            loading={isDeleteAvatarPending}
            classes={{
              loadingIndicator: styles.indicator
            }}
          >
            {t('Page.Profile.Settings.RemovePhoto')}
          </LoadingButton>
        </div>
      </div>
    </>
  );
};
