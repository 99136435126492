import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker } from 'react-map-gl';
import { ReactComponent as PinIcon } from 'assets/PinIcon.svg';
import cn from 'classnames';
import { Modal } from 'components';
import { DashboardCompany } from 'entities/DashboardCompany.entity';
import { ManufactureCard } from 'views/DashboardV2/ManufactureProfile/ManufactureCard';

import styles from './styles.module.scss';

interface Props {
  companies: DashboardCompany[] | null;
}

export const CompaniesLayer: FC<Props> = ({ companies }) => {
  const { t } = useTranslation();
  const [activeCompany, setActiveCompany] = useState<DashboardCompany | null>(
    null
  );
  const [isModalOpen, setModalOpen] = useState(false);

  const handleMarkerMouseOver =
    (company: DashboardCompany) => (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      setActiveCompany(company);
    };

  const handlePopupClose = useCallback(() => {
    if (!isModalOpen) setActiveCompany(null);
  }, [isModalOpen]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handlePopupClose);

    return () => {
      document.removeEventListener('click', handlePopupClose);
    };
  }, [handlePopupClose]);

  return (
    <>
      {companies?.map((company) => {
        if (company.latitude && company.longitude) {
          const isActiveCompany =
            activeCompany?.company_name === company.company_name;

          return (
            <Marker
              key={`${company.longitude}-${company.latitude}`}
              longitude={company.longitude}
              latitude={company.latitude}
              anchor="bottom"
              style={{
                zIndex: isActiveCompany ? 10 : 1
              }}
            >
              <div
                // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                onMouseOver={handleMarkerMouseOver(company)}
                onClick={handleMarkerMouseOver(company)}
                className={cn(styles.marker, isActiveCompany && styles.active)}
              >
                <div className={styles.popup}>
                  <h3 className={styles['popup-title']}>
                    {activeCompany?.company_name}
                  </h3>
                  <span className={styles['popup-address']}>
                    <PinIcon />
                    {activeCompany?.address}
                  </span>
                  <span
                    className={styles['popup-note']}
                    onClick={handleOpenModal}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: t('Page.Dashboard.Map.CompanyPopupNote')
                    }}
                  />
                </div>
              </div>
            </Marker>
          );
        }

        return null;
      })}

      <Modal
        paperClassName={styles.modal}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        {activeCompany && <ManufactureCard data={activeCompany} />}
      </Modal>
    </>
  );
};
