import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as LogoIcon } from 'assets/logo-inverted-sidebar.svg';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums';
import { Routes } from 'enums/Routes';

import styles from './styles.module.scss';

export const AdminHeader: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dashboardId, messageId } = useParams();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  return (
    <header className={styles.header}>
      <div className={styles['header-container']}>
        <div className={styles['logo-container']}>
          <Link to={Routes.NewChat} className={styles.logo}>
            <LogoIcon />
          </Link>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <Trans
              i18nKey="Common.SpectatorMode"
              values={{ username: dashboardData?.fullName }}
            >
              You are currently in Spectator-Mode, viewing
              <span className={styles.highlighted}>
                {dashboardData?.fullName}
              </span>
              dashboard
            </Trans>
          </div>
          <div
            onClick={() => navigate(Routes.NewChat)}
            className={styles.action}
          >
            {t('Common.StopSpectatorMode')}
          </div>
        </div>
      </div>
    </header>
  );
};
