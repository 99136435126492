import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SubscriptionCycles,
  SubscriptionPlans,
  SubscriptionStatuses
} from 'enums';
import { useScheduledSubscription, useUnpaidSubscription } from 'hooks';
import { useActiveSubscription } from 'hooks/api';
import { useUserInfo } from 'hooks/api/useUserInfo';

import { BillingDetails } from '../BillingDetails';
import { PlanDetails } from '../PlanDetails';
import { RenewSubscription } from '../RenewSubscription';
import { ScheduledPlanDetails } from '../ScheduledPlanDetails';
import { UnpaidPlanDetails } from '../UnpaidPlanDetails';

import styles from './styles.module.scss';

export const ProfileBilling = () => {
  const { t } = useTranslation();

  const { data: userInfo } = useUserInfo();
  const { data: activeSubscription } = useActiveSubscription();

  const scheduledSubscription = useScheduledSubscription();
  const { unpaidSubscription } = useUnpaidSubscription();

  const currentPlan = userInfo?.subscriptionPlan || SubscriptionPlans.Free;
  const currentBillingCycle =
    activeSubscription?.period || SubscriptionCycles.Monthly;
  const isBillingDetailsVisible = !!userInfo?.customerId;

  const currentPlanStatus = useMemo(() => {
    if (userInfo?.deactivatedAt) {
      return SubscriptionStatuses.Inactive;
    }

    return activeSubscription?.status || SubscriptionStatuses.Active;
  }, [activeSubscription?.status, userInfo?.deactivatedAt]);

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>{t('Page.Profile.Billing.Title')}</h1>
      <h4 className={styles.subheading}>
        {t('Page.Profile.Billing.PlanDetails')}
      </h4>
      {!unpaidSubscription && (
        <PlanDetails
          plan={currentPlan}
          cycle={currentBillingCycle}
          status={currentPlanStatus}
          renewalDate={activeSubscription?.expiresAt}
          scheduledSubscription={scheduledSubscription}
        />
      )}
      {!!unpaidSubscription?.status && (
        <UnpaidPlanDetails
          cycle={unpaidSubscription.period}
          plan={SubscriptionPlans.Premium}
          renewalDate={unpaidSubscription?.createdAt}
        />
      )}
      {!!scheduledSubscription?.createdAt && (
        <ScheduledPlanDetails
          plan={SubscriptionPlans.Premium}
          cycle={scheduledSubscription.period}
          startDate={scheduledSubscription.expiresAt}
        />
      )}
      {currentPlan === SubscriptionPlans.Premium &&
        activeSubscription?.expiresAt &&
        !scheduledSubscription && (
          <RenewSubscription renewalDate={activeSubscription?.expiresAt} />
        )}

      {isBillingDetailsVisible && <BillingDetails />}
    </div>
  );
};
